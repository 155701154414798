import React from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faGears,
  faKey,
  faPenToSquare,
  faTrash,
  faUser,
  faUsers,
} from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom'

function UserListItem(props) {
  return (
    <div className="card order-status-card">
      <div className="card-row">
        <div className="card-col col-w-140">
          <p className="card-title">{props.user.customer || 'Admin'}</p>
          <p className="card-subtitle">
            <FontAwesomeIcon
              icon={
                props.user.type === 'producer'
                  ? faUsers
                  : props.user.type === 'admin'
                  ? faGears
                  : faUser
              }
            />{' '}
            {props.user.name || 'Name'}
          </p>
        </div>
        <div className="card-col">{props.user.email}</div>
        <div
          className="edit-link"
          onClick={() => {
            var myHeaders = new Headers()
            myHeaders.append('Content-Type', 'application/json')
            myHeaders.append(
              'x-auth-token',
              localStorage.getItem('cut-catering-token'),
            )

            fetch('https://app.cutcatering.it/api/users/' + props.user._id, {
              method: 'DELETE',
              headers: myHeaders,
              redirect: 'follow',
            })
              .then(response => response.text())
              .then(result => {
                console.log(result)
                props.setDeleted(result)
              }) //()
              .catch(error => console.log('error', error))
          }}
        >
          <FontAwesomeIcon icon={faTrash} />
        </div>

        <div className="edit-link" onClick={props.resetPassword}>
          <FontAwesomeIcon icon={faKey} />
        </div>
        <Link to={`/users/${props.user._id}`} className="edit-link">
          <FontAwesomeIcon icon={faPenToSquare} />
        </Link>
      </div>
    </div>
  )
}

export default UserListItem

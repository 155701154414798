import React, { useEffect, useState } from 'react'
import TitleCard from '../components/TitleCard'
import OrderListItem from '../components/OrderListItem'
import FiltersAddBarOrders from '../components/FiltersAddBarOrders'
import moment from 'moment'

function Orders(props) {
  const [search, setSearch] = useState('')
  const [filterParams, setFilterParams] = useState({
    filter: 'now',
    end: new Date(),
    start: getFirstDayOfThisMonth(),
  })
  const [orders, setOrders] = useState([])
  const [deleted, setDeleted] = useState(null)

  useEffect(() => {
    var myHeaders = new Headers()
    myHeaders.append('Content-Type', 'application/json')
    myHeaders.append('x-auth-token', localStorage.getItem('cut-catering-token'))

    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow',
    }

    fetch(
      'https://app.cutcatering.it/api/orders/?' +
        new URLSearchParams({
          filter: filterParams.filter,
          end: filterParams.end.getTime(),
          start: filterParams.start.getTime(),
        }),
      requestOptions,
    )
      .then(response => response.json())
      .then(result => {
        console.log(result)
        setOrders(result)
      })
      .catch(error => console.log('error', error))
  }, [deleted, filterParams])

  const filterOptions = [
    { label: 'Oggi', value: 'now' },
    { label: 'Domani', value: 'tomorrow' },
    { label: 'Range', value: 'range' },
    { label: 'Tutti', value: 'all' },
  ]

  let getOrders = function () {
    return orders.filter(e => {
      if (!search || e.customer.toLowerCase().includes(search.toLowerCase()))
        return true

      if (!search || e.name.toLowerCase().includes(search.toLowerCase()))
        return true
      return false
    })
  }

  let getOrdersArray = function () {
    let rows = getOrders().map((e, key) => [
      e.customer,
      e.name,
      e.type,
      getPeople(e.items),
      e.account || 'guest',
      formatDate(e.time),
    ])
    let customers = {}
    let days = {}

    rows.forEach(([customerD, nameD, typeD, peopleD, accountD, dateD]) => {
      if (!customers[customerD])
        customers[customerD] = { name: customerD, all: 0 }
      if (!customers[customerD][dateD]) customers[customerD][dateD] = 0
      customers[customerD][dateD] += peopleD
      customers[customerD].all += peopleD
      days[dateD] = dateD
    })

    let rowsByDay = []
    let rowsByMonth = []

    Object.values(customers).forEach(cm => {
      let counter = 0
      Object.values(days).forEach(day => {
        counter += cm[day]
        rowsByDay.push([cm.name, '', '', cm[day], '', days[day]])
      })

      rowsByMonth.push([cm.name, '', '', counter, '', ''])
    })

    const head1 = ['cliente', 'nome', 'tipo', 'persone', 'account', 'data']
    const empty = ['', '', '', '', '', '']

    return [
      head1,
      ...rows,
      empty,
      empty,
      empty,
      ...rowsByDay,
      empty,
      empty,
      empty,
      ...rowsByMonth,
    ]
  }

  let downloadBtn = function () {
    let csvContent =
      'data:text/csv;charset=utf-8,' +
      getOrdersArray()
        .map(e => e.join(';'))
        .join('\n')

    var encodedUri = encodeURI(csvContent)
    var link = document.createElement('a')
    link.setAttribute('href', encodedUri)
    link.setAttribute('download', 'cut-catering-export.csv')
    document.body.appendChild(link) // Required for FF

    link.click() // This will download the data file named "my_data.csv".
  }

  return (
    <div className="dashboard-container">
      <div className="column">
        <TitleCard title="Ordini" subtitle={`Tutti gli ordini ricevuti!`} />
      </div>
      <div className="column">
        <FiltersAddBarOrders
          search={search}
          setSearch={setSearch}
          newPath="/customers/new"
          downloadBtn={downloadBtn}
          filterOptions={filterOptions}
          filterParams={filterParams}
          setFilterParams={setFilterParams}
        />
      </div>
      <div className="column-wrap">
        <div className="column-100 column-child-lg-50">
          {getOrders().map((el, key) => (
            <div key={key} className="col-element">
              <OrderListItem order={el} setDeleted={setDeleted} />
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

function getPeople(items) {
  let categories = {}
  items.forEach(element => {
    if (!categories[element.categoryId]) categories[element.categoryId] = 0
    categories[element.categoryId] += element.quantity
  })
  return Math.max(...Object.values(categories)) || 0
}

function formatDate(time) {
  const date = new Date(time) // example - replace with your dates
  return moment(date).format('YYYY-MM-DD')
}

function getFirstDayOfMonth(year, month) {
  return new Date(year, month, 1)
}

function getFirstDayOfThisMonth() {
  const date = new Date()
  return getFirstDayOfMonth(date.getFullYear(), date.getMonth())
}

export default Orders

import React, { useEffect, useState } from 'react'
import TitleCard from '../components/TitleCard'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faUpload } from '@fortawesome/free-solid-svg-icons'

import Select from 'react-select'

import { useHistory, useParams } from 'react-router-dom'

const allergeni = [
  { value: 'glutine', label: 'Glutine' },
  { value: 'crostacei', label: 'Crostacei' },
  { value: 'uova', label: 'Uova' },
  { value: 'pesce', label: 'Pesce' },
  { value: 'arachidi', label: 'Arachidi' },
  { value: 'soia', label: 'Soia' },
  { value: 'latte', label: 'Latte' },
  { value: 'frutta-guscio', label: 'Frutta a Guscio' },
  { value: 'sedano', label: 'Sedano' },
  { value: 'senape', label: 'Senape' },
  { value: 'sesamo', label: 'Sesamo' },
  { value: 'solfiti', label: 'Solfiti' },
  { value: 'lupini', label: 'Lupini' },
  { value: 'molluschi', label: 'Molluschi' },
]

const features = [
  { value: 'vegan', label: 'Vegano' },
  { value: 'vegetarian', label: 'Vegetariano' },
  { value: 'gluten', label: 'Glutine' },
  { value: 'lactose', label: 'Latte' },
]

function MenuNew(props) {
  const [menu, setMenu] = useState([])
  const [menuName, setMenuName] = useState('')
  const [menuDescription, setMenuDescription] = useState('')
  const { id } = useParams()
  const history = useHistory()

  useEffect(() => {
    if (id !== 'new') {
      var myHeaders = new Headers()
      myHeaders.append('Content-Type', 'application/json')
      myHeaders.append(
        'x-auth-token',
        localStorage.getItem('cut-catering-token'),
      )

      var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow',
      }

      fetch('https://app.cutcatering.it/api/menus/' + id, requestOptions)
        .then(response => response.json())
        .then(result => {
          console.log(result)
          setMenu(result.categories)
          setMenuName(result.name)
          setMenuDescription(result.description)
        })
        .catch(error => console.log('error', error))
    }
  }, [id])

  return (
    <div className="dashboard-container">
      <div className="column">
        <TitleCard
          title="Nuovo menu"
          subtitle={`Inserisci sezioni e piatti!`}
        />
      </div>
      <div className="toggle-input-row">
        <span className="nome-title-sec">Nome Menu</span>
        <input
          type="text"
          value={menuName}
          onChange={e => setMenuName(e.target.value)}
        />
      </div>
      <div className="toggle-input-row">
        <span className="nome-title-sec">Descrizione</span>
        <input
          type="text"
          value={menuDescription}
          onChange={e => setMenuDescription(e.target.value)}
        />
      </div>
      <div className="header-add">
        <div
          className="card-button card-button-primary"
          onClick={() => {
            menu.push({
              name: '',
              single: true,
              optional: false,
              options: [],
            })
            setMenu([...menu])
          }}
        >
          <FontAwesomeIcon icon={faPlus} /> Nuova Sezione
        </div>
      </div>
      <div className="column-wrap">
        <div className="contenitore-sezioni-template">
          {menu.map((section, key) => (
            <div key={key} className="col-element">
              <div className="new-menu-section pos-relative">
                <span
                  className="options-menu-section-remove"
                  onClick={() => {
                    menu.splice(key, 1)
                    setMenu([...menu])
                  }}
                >
                  X
                </span>
                <div className="toggle-input-row">
                  <span className="nome-title-sec">Nome sezione</span>
                  <input
                    type="text"
                    value={section.name}
                    onChange={e => {
                      section.name = e.target.value
                      setMenu([...menu])
                    }}
                  />
                </div>
                <div className="toggle-input-row">
                  <span className="toggle-input-title">Singola</span>
                  <input
                    type="checkbox"
                    checked={section.single}
                    onClick={() => {
                      section.single = !section.single
                      setMenu([...menu])
                    }}
                  />
                </div>

                <div className="toggle-input-row">
                  <span className="toggle-input-title">Facoltativa</span>
                  <input
                    type="checkbox"
                    checked={section.optional}
                    onClick={() => {
                      section.optional = !section.optional
                      setMenu([...menu])
                    }}
                  />
                </div>
                <div
                  className="button-add-option-menu"
                  onClick={() => {
                    section.options.push({
                      name: '',
                      description: '',
                      allergeni: [],
                      features: [],
                    })
                    setMenu([...menu])
                  }}
                >
                  <FontAwesomeIcon icon={faPlus} /> Nuova opzione
                </div>
                {section.options.map((option, key2) => (
                  <div key={key2} className="option-single-admin pos-relative">
                    <span
                      className="options-menu-section-remove"
                      onClick={() => {
                        section.options.splice(key2, 1)
                        setMenu([...menu])
                      }}
                    >
                      X
                    </span>
                    <div className="option-left-row">
                      <div className="toggle-input-row">
                        <span className="nome-title-sec">Nome</span>
                        <input
                          type="text"
                          value={option.name}
                          onChange={e => {
                            option.name = e.target.value
                            setMenu([...menu])
                          }}
                        />
                      </div>
                      <div className="toggle-input-row">
                        <span className="nome-title-sec">Descrizione</span>
                        <textarea
                          value={option.description}
                          onChange={e => {
                            option.description = e.target.value
                            setMenu([...menu])
                          }}
                        />
                      </div>
                    </div>
                    <div className="options-right-row">
                      <div className="allergeni">
                        <div className="allergeni-title">Allergeni:</div>
                        {option.allergeni.map((allergene, key3) => (
                          <span
                            key={key3}
                            className="options-menu-allerg-section"
                          >
                            {allergene}{' '}
                            <span
                              className="options-menu-allerg-section-remove"
                              onClick={() => {
                                option.allergeni.splice(key3, 1)
                                setMenu([...menu])
                              }}
                            >
                              X
                            </span>
                          </span>
                        ))}
                      </div>
                      <div className="new-allergene-item">
                        <Select
                          value={null}
                          onChange={e => {
                            console.log(e)
                            if (!option.allergeni.includes(e.value))
                              option.allergeni.push(e.value)
                            setMenu([...menu])
                          }}
                          options={allergeni}
                        />
                      </div>
                      <div className="allergeni">
                        <div className="allergeni-title">Caratteristiche:</div>
                        {option.features.map((allergene, key3) => (
                          <span
                            key={key3}
                            className="options-menu-allerg-section"
                          >
                            {allergene}{' '}
                            <span
                              className="options-menu-allerg-section-remove"
                              onClick={() => {
                                option.features.splice(key3, 1)
                                setMenu([...menu])
                              }}
                            >
                              X
                            </span>
                          </span>
                        ))}
                      </div>
                      <div className="new-allergene-item">
                        <Select
                          value={null}
                          onChange={e => {
                            console.log(e)
                            if (!option.features.includes(e.value))
                              option.features.push(e.value)
                            setMenu([...menu])
                          }}
                          options={features}
                        />
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
      {id !== 'new' ? (
        <div className="header-add">
          <div
            className="card-button card-button-primary"
            onClick={() => {
              var myHeaders = new Headers()
              myHeaders.append('Content-Type', 'application/json')
              myHeaders.append(
                'x-auth-token',
                localStorage.getItem('cut-catering-token'),
              )

              fetch('https://app.cutcatering.it/api/menus/' + id, {
                method: 'POST',
                headers: myHeaders,
                body: JSON.stringify({
                  categories: menu,
                  name: menuName,
                  description: menuDescription,
                }),
                redirect: 'follow',
              })
                .then(response => response.text())
                .then(result => {
                  console.log(result)
                  history.push('/menu')
                }) //()
                .catch(error => console.log('error', error))
            }}
          >
            <FontAwesomeIcon icon={faUpload} /> Modifica
          </div>
          <div
            className="card-button card-button-primary"
            onClick={() => {
              var myHeaders = new Headers()
              myHeaders.append('Content-Type', 'application/json')
              myHeaders.append(
                'x-auth-token',
                localStorage.getItem('cut-catering-token'),
              )

              fetch('https://app.cutcatering.it/api/menus/', {
                method: 'POST',
                headers: myHeaders,
                body: JSON.stringify({
                  categories: menu,
                  name: menuName,
                  description: menuDescription,
                }),
                redirect: 'follow',
              })
                .then(response => response.text())
                .then(result => {
                  console.log(result)
                  history.push('/menu')
                }) //()
                .catch(error => console.log('error', error))
            }}
          >
            <FontAwesomeIcon icon={faUpload} /> Salva come nuovo
          </div>
        </div>
      ) : (
        <div className="header-add">
          <div
            className="card-button card-button-primary"
            onClick={() => {
              var myHeaders = new Headers()
              myHeaders.append('Content-Type', 'application/json')
              myHeaders.append(
                'x-auth-token',
                localStorage.getItem('cut-catering-token'),
              )

              fetch('https://app.cutcatering.it/api/menus', {
                method: 'POST',
                headers: myHeaders,
                body: JSON.stringify({
                  categories: menu,
                  name: menuName,
                  description: menuDescription,
                }),
                redirect: 'follow',
              })
                .then(response => response.text())
                .then(result => {
                  console.log(result)
                  history.push('/menu')
                }) //()
                .catch(error => console.log('error', error))
            }}
          >
            <FontAwesomeIcon icon={faUpload} /> SALVA
          </div>
        </div>
      )}
    </div>
  )
}

export default MenuNew

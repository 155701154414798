import React, { useState } from 'react'
import loadingImg from '../loading.svg'

import { useHistory, useParams } from 'react-router-dom'

import logo from '../img/logo-white.png'

import '../login.css'

const baseUrl = 'https://app.cutcatering.it/api'

function PasswordReset(props) {
  let [password2, setPassword2] = useState('')
  let [password, setPassword] = useState('')
  let [error, setError] = useState('')
  let [loading, setLoading] = useState(false)

  const { id, token } = useParams()
  const history = useHistory()

  return (
    <>
      <div className="login-page">
        <div className="login-box">
          <div className="login-title-box">
            {/* <h1 className="login-title">Admin Panel</h1> */}
            <img src={logo} className="logo-small" alt="logo app"></img>
          </div>
          <div className="login-input-box">
            <h1 className="login-title">Nuova password</h1>

            <div className="login-input-row login-name-row">
              <input
                className="login-input"
                type="password"
                name="password"
                id="password"
                placeholder="password..."
                onKeyDown={e => {
                  if (e.code === 'Enter') {
                    resetPassword(
                      password,
                      password2,
                      setError,
                      setLoading,
                      setPassword,
                      id,
                      token,
                      history,
                    )
                  }
                }}
                value={password}
                onChange={event => setPassword(event.target.value)}
              />
            </div>
            <div className="login-input-row login-name-row">
              <input
                className="login-input"
                type="password"
                id="password2"
                name="password2"
                placeholder="repeat password..."
                onKeyDown={e => {
                  if (e.code === 'Enter') {
                    resetPassword(
                      password,
                      password2,
                      setError,
                      setLoading,
                      setPassword,
                      id,
                      token,
                      history,
                    )
                  }
                }}
                value={password2}
                onChange={event => setPassword2(event.target.value)}
              />
            </div>
            <div className="login-submit-row">
              <button
                className="login-button"
                onClick={() => {
                  resetPassword(
                    password,
                    password2,
                    setError,
                    setLoading,
                    setPassword,
                    id,
                    token,
                    history,
                  )
                }}
                onKeyDown={e => {
                  if (e.code === 'Enter') {
                    resetPassword(
                      password,
                      password2,
                      setError,
                      setLoading,
                      setPassword,
                      id,
                      token,
                      history,
                    )
                  }
                }}
                type="submit"
              >
                {loading ? <img src={loadingImg} alt="loading..." /> : 'Salva'}
              </button>
            </div>
          </div>
          {error ? (
            <div className="error-box">
              <div className="error-row">
                <p>{error}</p>
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </>
  )
}
async function resetPassword(
  password,
  password2,
  setError,
  setLoading,
  setPassword,
  id,
  token,
  history,
) {
  let response = {}

  if (!password) {
    setLoading(false)
    setError('La password non può essere vuota!')
    setPassword('')
    setTimeout(() => {
      setError(false)
    }, 3000)
    return false
  }

  if (password.length < 8) {
    setLoading(false)
    setError('Supera gli 8 caratteri!')
    setPassword('')
    setTimeout(() => {
      setError(false)
    }, 3000)
    return false
  }

  if (password !== password2) {
    setLoading(false)
    setError('Le password non corrispondono!')
    setPassword('')
    setTimeout(() => {
      setError(false)
    }, 3000)
    return false
  }

  try {
    response = await fetch(`${baseUrl}/users/set-password/${id}/${token}`, {
      method: 'POST',
      mode: 'cors',
      cache: 'no-cache',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        password: password.trim(),
      }),
    })
    if (!response.ok) {
      let error = 'server error, retry!'
      try {
        error = await response.text()
      } catch {}
      setLoading(false)
      setError(error)
      setPassword('')
      setTimeout(() => {
        setError(false)
      }, 3000)
      return
    }
    let data = {}
    try {
      data = await response.json()
    } catch (e) {
      console.log(e.message)
    }
    if (data && data.email) {
      history.push('/login')
    }
  } catch (e) {
    setLoading(false)
    setError("can't connect to server!")
    setTimeout(() => {
      setError(false)
    }, 3000)
    return
  }
}

export default PasswordReset

import {
  faCow,
  faEgg,
  faLeaf,
  faRefresh,
  faRightFromBracket,
  faTrash,
  faWheatAwnCircleExclamation,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import logo from '../img/white-small.png'

import image from '../img/waving-man.svg'
import moment from 'moment'

function Order(props) {
  const [menu, setMenu] = useState([])
  const [currentOrders, setCurrentOrders] = useState([])
  const [userName, setUsername] = useState('')
  const [deliveryTime, setDeliveryTime] = useState('')
  const [isProducer, setIsProducer] = useState(false)
  const [status, setStatus] = useState('closed')
  const [reload, triggerReload] = useState(0)
  const [refresh, setRefresh] = useState(0)
  const [options, setOptions] = useState({})
  const [order, setOrder] = useState({})
  const { id } = useParams()

  useEffect(() => {
    let interval = setInterval(() => {
      setRefresh(refresh + 1)
    }, 10000)
    return () => {
      clearInterval(interval)
    }
  }, [])

  useEffect(() => {
    console.log(props.user)
    const orderId = localStorage.getItem('lastOrderID')

    if (!orderId) {
      setOrder({})
      return () => {}
    }
    var myHeaders = new Headers()
    myHeaders.append('Content-Type', 'application/json')

    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow',
    }

    fetch('https://app.cutcatering.it/api/orders/' + orderId, requestOptions)
      .then(response => response.json())
      .then(result => {
        console.log(result)
        if (
          !result?.deliveryTime ||
          moment(result.deliveryTime).isBefore(moment())
        ) {
          localStorage.removeItem('lastOrderID')
          setOrder({})
          return () => {}
        }
        setOrder(result)
      })
      .catch(error => {
        localStorage.removeItem('lastOrderID')
        setOrder({})
        return () => {}
      })
  }, [props.user, id, reload])

  useEffect(() => {
    console.log(props.user)
    var myHeaders = new Headers()
    myHeaders.append('Content-Type', 'application/json')

    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow',
    }

    fetch(
      'https://app.cutcatering.it/api/ordersingle/' +
        (props.user ? props.user._id : id),
      requestOptions,
    )
      .then(response => response.json())
      .then(result => {
        console.log(result)
        setMenu(result?.categories || [])
        setCurrentOrders(result?.currentOrders || [])
        setStatus(result?.currentStatus || 'closed')
        setIsProducer(result?.producer || false)
        const delivery = moment(result?.currentDay?.orderDate)
        setDeliveryTime(
          `${delivery.date() > 9 ? delivery.date() : '0' + delivery.date()}/${
            delivery.month() + 1 > 9
              ? delivery.month() + 1
              : '0' + (delivery.month() + 1)
          }/${delivery.year()}`,
        )
      })
      .catch(error => console.log('error', error))
  }, [props.user, id, reload])

  const deleteOrder = order => {
    const orderId = order._id

    if (!orderId) return
    var myHeaders = new Headers()
    myHeaders.append('Content-Type', 'application/json')

    var requestOptions = {
      method: 'DELETE',
      headers: myHeaders,
      redirect: 'follow',
    }

    fetch('https://app.cutcatering.it/api/orders/' + orderId, requestOptions)
      .then(response => response.text())
      .then(result => {
        triggerReload(reload + 1)
        console.log(result)
      })
      .catch(error => console.log('error', error))
  }

  if (order?._id && !isProducer)
    return (
      <>
        <div className="nav-order">
          <div className="nav-order-title">
            <img src={logo} className="logo-sidebar" alt="link home"></img>
          </div>
          {props.user ? (
            <div className="nav-order-user">
              <span className="nav-order-logout">
                <FontAwesomeIcon icon={faRightFromBracket} />
              </span>
              {' ' + props?.user?.name}
            </div>
          ) : null}
        </div>
        <div className="order-confirm">
          <div className="card instruction-card">
            <h1 className="instruction-title">Grazie maestr*</h1>
            <h2 className="instruction-subtitle">
              <b>Ordine confermato!</b> Ora è solo questione di tempo 😉
            </h2>
            <img className="image-fix-dx" src={image} alt="Man waving at you" />
          </div>
        </div>

        <div className="order-confirm">
          <div className="card resizable-card">
            <div className="name-box">{order.name}</div>
            <div className="time-box">{order.customer}</div>
            <div className="items-box">
              {order?.items?.map((item, key) => {
                return (
                  <div className="items-list-element" key={key}>
                    <span className="item-prod-quantity-confirm">
                      {item.quantity <= 1 ? null : item.quantity}
                    </span>
                    {item.name}
                  </div>
                )
              })}
            </div>

            <div className="time-box1">
              <div>Ora ordine</div>
              <div>{parseDate(order.time)}</div>
            </div>
            <div className="time-box2">
              <div>Ora cosegna</div>
              <div>{parseDate(order.deliveryTime)}</div>
            </div>
          </div>
        </div>
        <div className="reload-container">
          <div
            className="card-button card-button-primary"
            onClick={() => {
              triggerReload(reload + 1)
            }}
          >
            <FontAwesomeIcon icon={faRefresh} /> AGGIORNA
          </div>
          {order.time &&
          moment(order.time).add(2, 'minutes').isAfter(moment()) ? (
            <div
              className="card-button card-button-secondary"
              onClick={() => {
                deleteOrder(order)
              }}
            >
              <FontAwesomeIcon icon={faTrash} /> ANNULLA
            </div>
          ) : null}
        </div>
      </>
    )

  if (!menu?.length || status !== 'open')
    return (
      <>
        <div className="nav-order">
          <div className="nav-order-title">
            {props.user ? <div></div> : null}
            <img src={logo} className="logo-sidebar" alt="link home"></img>
          </div>
          {props.user ? (
            <div className="nav-order-user">
              <span
                className="nav-order-logout"
                onClick={() => {
                  localStorage.removeItem('cut-catering-token')
                  props.setUser(null)
                }}
              >
                <FontAwesomeIcon icon={faRightFromBracket} />
              </span>{' '}
              Simone
            </div>
          ) : null}
        </div>
        <div className="instructions">
          <div className="card instruction-card">
            <h1 className="instruction-title">Non puoi ordinare</h1>
            <h2 className="instruction-subtitle">
              <b>Ci spiace!</b> Chiedi al tuo producer!
            </h2>
            <img className="image-fix-dx" src={image} alt="Man waving at you" />
          </div>
        </div>
        <div className="instructions">
          <div className="actual-orders-delimiter">
            {currentOrders?.length ? 'Ordini correnti:' : ''}
          </div>
          {currentOrders.map((order, key) => (
            <div className="order-confirm" key={key}>
              <div className="card resizable-card">
                <div className="name-box">{order.name}</div>
                <div className="items-box">
                  {order?.items?.map((item, key) => {
                    return (
                      <div className="items-list-element" key={key}>
                        <span className="item-prod-quantity-confirm">
                          {item.quantity <= 1 ? null : item.quantity}
                        </span>
                        {item.name}
                      </div>
                    )
                  })}
                </div>

                <div className="time-box1">
                  <div>Ora ordine</div>
                  <div>{parseDate(order.time)}</div>
                </div>
                <div className="time-box2">
                  <div>Ora cosegna</div>
                  <div>{parseDate(order.deliveryTime)}</div>
                </div>
              </div>
            </div>
          ))}
          <div className="reload-container">
            <div
              className="card-button card-button-primary"
              onClick={() => {
                triggerReload(reload + 1)
              }}
            >
              <FontAwesomeIcon icon={faRefresh} /> AGGIORNA
            </div>
          </div>
        </div>
      </>
    )

  if (isProducer)
    return (
      <>
        <div className="nav-order">
          <div className="nav-order-title">
            {props.user ? <div></div> : null}
            <img src={logo} className="logo-sidebar" alt="link home"></img>
          </div>
          {props.user ? (
            <div className="nav-order-user">
              <span
                className="nav-order-logout"
                onClick={() => {
                  localStorage.removeItem('cut-catering-token')
                  props.setUser(null)
                }}
              >
                <FontAwesomeIcon icon={faRightFromBracket} />
              </span>
              {' ' + props.user.name}
            </div>
          ) : null}
        </div>
        <div className="instructions">
          <div className="card instruction-card">
            <h1 className="instruction-title">Welcome producer</h1>
            <h2 className="instruction-subtitle">
              <b>e... CUT!</b> Ordina per la tua crew o gestisci i loro ordini!
            </h2>
            <p className="instruction-delivery-time">
              Consegna: {deliveryTime}
            </p>
            <img className="image-fix-dx" src={image} alt="Man waving at you" />
          </div>
          {menu.map((sezione, key) => (
            <div key={key} className="card order-category-card">
              <h2 className="instruction-title">{sezione.name}</h2>
              {sezione.options.map((option, key2) => {
                if (!options[sezione.name]) options[sezione.name] = {}
                return (
                  <div
                    className="opzione-container padding-top-boost"
                    key={key2}
                  >
                    <div className="up-down-counters">
                      <span
                        className="ud-arrow"
                        onClick={() => {
                          let newOptions = { ...options }
                          if (!newOptions[sezione.name][option.name])
                            newOptions[sezione.name][option.name] = 0
                          newOptions[sezione.name][option.name] -= 1
                          if (newOptions[sezione.name][option.name] < 0)
                            newOptions[sezione.name][option.name] = 0

                          setOptions(newOptions)
                        }}
                      >
                        -
                      </span>
                      <span className="ud-number">
                        {options[sezione.name][option.name] || 0}
                      </span>
                      <span
                        className="ud-arrow"
                        onClick={() => {
                          let newOptions = { ...options }
                          if (!newOptions[sezione.name][option.name])
                            newOptions[sezione.name][option.name] = 0
                          newOptions[sezione.name][option.name] += 1

                          setOptions(newOptions)
                        }}
                      >
                        +
                      </span>
                    </div>
                    <div className="nome-portata">{option.name}</div>
                    <div className="features-portata">
                      {getFeatures(option.features)}
                    </div>
                    <div className="descrizione-portata">
                      {option.description}
                    </div>
                    <div className="allergeni-portata">
                      {getAllergeni(option.allergeni)}
                    </div>
                  </div>
                )
              })}
            </div>
          ))}

          <div className="button-confirm-container">
            {!props.user && (
              <div className="nome-ordine-input-row">
                <span className="nome-title-sec">Come ti chiami?</span>
                <input
                  type="text"
                  value={userName}
                  onChange={e => setUsername(e.target.value)}
                />
              </div>
            )}
            {userName.length > 3 || props.user ? (
              <div
                className="card-button card-button-primary"
                onClick={() => {
                  var myHeaders = new Headers()
                  myHeaders.append('Content-Type', 'application/json')

                  let items = []
                  for (let sezione of menu) {
                    for (let option of sezione.options) {
                      if (sezione.single && !isProducer) {
                        if (options[sezione.name] === option.name)
                          items.push({
                            name: option.name,
                            description: option.description,
                            allergeni: option.allergeni,
                            features: option.features,
                            id: option._id,
                            category: sezione.name,
                            categoryId: sezione._id,
                            quantity: 1,
                          })
                      } else if (!sezione.single && !isProducer) {
                        if (
                          options[sezione.name] &&
                          options[sezione.name][option.name]
                        )
                          items.push({
                            name: option.name,
                            description: option.description,
                            allergeni: option.allergeni,
                            features: option.features,
                            id: option._id,
                            category: sezione.name,
                            categoryId: sezione._id,
                            quantity: 1,
                          })
                      } else if (isProducer) {
                        if (
                          options[sezione.name] &&
                          options[sezione.name][option.name]
                        )
                          items.push({
                            name: option.name,
                            description: option.description,
                            allergeni: option.allergeni,
                            features: option.features,
                            id: option._id,
                            category: sezione.name,
                            categoryId: sezione._id,
                            quantity: options[sezione.name][option.name],
                          })
                      }
                    }
                  }

                  fetch(
                    'https://app.cutcatering.it/api/ordersingle/' +
                      (props.user ? props.user._id : id),
                    {
                      method: 'POST',
                      headers: myHeaders,
                      body: JSON.stringify({
                        name: userName,
                        items,
                      }),
                      redirect: 'follow',
                    },
                  )
                    .then(response => response.json())
                    .then(result => {
                      console.log(result)
                      setOptions({})
                      setUsername('')
                      triggerReload(reload + 1)
                    }) //()
                    .catch(error => console.log('error', error))
                }}
              >
                Conferma
              </div>
            ) : (
              <div className="card-button card-button-primary card-button-disabled">
                Conferma
              </div>
            )}
          </div>
        </div>

        <div className="instructions">
          <div className="actual-orders-delimiter">
            Ordini correnti:{currentOrders?.length ? '' : ' 0'}
          </div>
          {currentOrders.map((order, key) => (
            <div className="order-confirm" key={key}>
              <div className="card resizable-card">
                <div className="name-box">{order.name}</div>
                <div
                  className="time-box delete-box"
                  onClick={() => {
                    deleteOrder(order)
                  }}
                >
                  X Annulla
                </div>
                <div className="items-box">
                  {order?.items?.map((item, key) => {
                    return (
                      <div className="items-list-element" key={key}>
                        <span className="item-prod-quantity-confirm">
                          {item.quantity <= 1 ? null : item.quantity}
                        </span>
                        {item.name}
                      </div>
                    )
                  })}
                </div>

                <div className="time-box1">
                  <div>Ora ordine</div>
                  <div>{parseDate(order.time)}</div>
                </div>
                <div className="time-box2">
                  <div>Ora cosegna</div>
                  <div>{parseDate(order.deliveryTime)}</div>
                </div>
              </div>
            </div>
          ))}
          <div className="reload-container">
            <div
              className="card-button card-button-primary"
              onClick={() => {
                triggerReload(reload + 1)
              }}
            >
              <FontAwesomeIcon icon={faRefresh} /> AGGIORNA
            </div>
          </div>
        </div>
      </>
    )

  return (
    <>
      <div className="nav-order">
        <div className="nav-order-title">
          {props.user ? <div></div> : null}
          <img src={logo} className="logo-sidebar" alt="link home"></img>
        </div>
        {props.user ? (
          <div className="nav-order-user">
            <span
              className="nav-order-logout"
              onClick={() => {
                localStorage.removeItem('cut-catering-token')
                props.setUser(null)
              }}
            >
              <FontAwesomeIcon icon={faRightFromBracket} />
            </span>
            {' ' + props.user.name}
          </div>
        ) : null}
      </div>
      <div className="instructions">
        <div className="card instruction-card">
          <h1 className="instruction-title">Welcome maestr*</h1>
          <h2 className="instruction-subtitle">
            <b>e... CUT!</b> Scegli un piatto per ogni sezione e conferma!
          </h2>
          <p className="instruction-delivery-time">Consegna: {deliveryTime}</p>
          <img className="image-fix-dx" src={image} alt="Man waving at you" />
        </div>
        {menu.map((sezione, key) => (
          <div key={key} className="card order-category-card">
            <h2 className="instruction-title">{sezione.name}</h2>
            {sezione.options.map((option, key2) => {
              let isSelected = false
              if (sezione.single) {
                isSelected = options[sezione.name] === option.name
              } else {
                isSelected =
                  options[sezione.name] && options[sezione.name][option.name]
              }
              return (
                <div
                  className={`opzione-container ${
                    isSelected ? 'selected' : ''
                  }`}
                  key={key2}
                  onClick={() => {
                    let newOptions = { ...options }
                    if (sezione.single) {
                      newOptions[sezione.name] = option.name
                    } else {
                      if (!newOptions[sezione.name])
                        newOptions[sezione.name] = {}
                      newOptions[sezione.name][option.name] = newOptions[
                        sezione.name
                      ][option.name]
                        ? false
                        : true
                    }
                    setOptions(newOptions)
                  }}
                >
                  {sezione.single ? (
                    <div className="radio-status"></div>
                  ) : (
                    <div className="check-status"></div>
                  )}
                  <div className="nome-portata">{option.name}</div>
                  <div className="features-portata">
                    {getFeatures(option.features)}
                  </div>
                  <div className="descrizione-portata">
                    {option.description}
                  </div>
                  <div className="allergeni-portata">
                    {getAllergeni(option.allergeni)}
                  </div>
                </div>
              )
            })}
            {sezione.single && sezione.optional ? (
              <div
                className={`opzione-container ${
                  options[sezione.name] === 'empty' ? 'selected' : ''
                }`}
                onClick={() => {
                  let newOptions = { ...options }
                  newOptions[sezione.name] = 'empty'
                  setOptions(newOptions)
                }}
              >
                <div className="radio-status"></div>
                <div className="nome-portata">Nulla</div>
                <div className="features-portata"></div>
                <div className="descrizione-portata">
                  se sai di non mangiare, non sprecare!
                </div>
                <div className="allergeni-portata"></div>
              </div>
            ) : null}
          </div>
        ))}

        <div className="button-confirm-container">
          {!props.user && (
            <div className="nome-ordine-input-row">
              <span className="nome-title-sec">Come ti chiami?</span>
              <input
                type="text"
                value={userName}
                onChange={e => setUsername(e.target.value)}
              />
            </div>
          )}
          {(userName.length > 3 || props.user) && (
            <div
              className="card-button card-button-primary"
              onClick={() => {
                var myHeaders = new Headers()
                myHeaders.append('Content-Type', 'application/json')

                let items = []
                for (let sezione of menu) {
                  for (let option of sezione.options) {
                    if (sezione.single) {
                      if (options[sezione.name] === option.name)
                        items.push({
                          name: option.name,
                          description: option.description,
                          allergeni: option.allergeni,
                          features: option.features,
                          id: option._id,
                          category: sezione.name,
                          categoryId: sezione._id,
                          quantity: 1,
                        })
                    } else {
                      if (
                        options[sezione.name] &&
                        options[sezione.name][option.name]
                      )
                        items.push({
                          name: option.name,
                          description: option.description,
                          allergeni: option.allergeni,
                          features: option.features,
                          id: option._id,
                          category: sezione.name,
                          categoryId: sezione._id,
                          quantity: 1,
                        })
                    }
                  }
                }

                fetch(
                  'https://app.cutcatering.it/api/ordersingle/' +
                    (props.user ? props.user._id : id),
                  {
                    method: 'POST',
                    headers: myHeaders,
                    body: JSON.stringify({
                      name: userName,
                      items,
                    }),
                    redirect: 'follow',
                  },
                )
                  .then(response => response.json())
                  .then(result => {
                    console.log(result)
                    localStorage.setItem('lastOrderID', result._id)
                    triggerReload(reload + 1)
                  }) //()
                  .catch(error => console.log('error', error))
              }}
            >
              Conferma
            </div>
          )}
        </div>
        <div className="reload-container">
          <div
            className="card-button card-button-primary"
            onClick={() => {
              triggerReload(reload + 1)
            }}
          >
            <FontAwesomeIcon icon={faRefresh} /> AGGIORNA
          </div>
        </div>
      </div>
    </>
  )
}

function getFeatures(features) {
  let returnable = []
  let key = 0
  for (let feature of features) {
    key++
    if (feature === 'vegan')
      returnable.push(<FontAwesomeIcon icon={faLeaf} key={key} />)
    if (feature === 'vegetarian')
      returnable.push(<FontAwesomeIcon icon={faEgg} key={key} />)
    if (feature === 'gluten')
      returnable.push(
        <FontAwesomeIcon icon={faWheatAwnCircleExclamation} key={key} />,
      )
    if (feature === 'lactose')
      returnable.push(<FontAwesomeIcon icon={faCow} key={key} />)
  }

  return returnable
}

function getAllergeni(allergeni) {
  return allergeni.join(' ')
}

function parseDate(time) {
  let yourDate = new Date(time)
  const offset = yourDate.getTimezoneOffset()
  yourDate = new Date(yourDate.getTime() - offset * 60 * 1000)
  return yourDate
    .toISOString()
    .split('.')[0]
    .split('T')
    .join(' ')
    .substring(0, 16)
}

export default Order

import React, { useState } from 'react'
import TitleCard from '../components/TitleCard'
import NumberCard from '../components/NumberCard'
import CustomerOrderStatus from '../components/CustomerOrderStatus'
import MenuOptionStatus from '../components/MenuOptionStatus'

function Dashboard(props) {
  const [customers, setCustomers] = useState([
    {
      name: 'Rental',
      orders: 12,
      status: true,
      _id: '234565432234',
    },
    {
      name: 'Studio 1',
      orders: 0,
      status: false,
      _id: '234565412334',
    },
    {
      name: 'Studio 3',
      orders: 2,
      status: true,
      _id: '234565437734',
    },
  ])
  const [menu, setmenu] = useState([
    {
      name: 'Carbonara',
      description: 'Pasta tipica Romana',
      price: 7,
      available: true,
      _id: '234565432234',
    },
    {
      name: 'Pollo al Curry',
      description: 'In crema di panna vegetale, con riso a parte',
      orders: 0,
      available: false,
      _id: '234565412334',
    },
    {
      name: 'Tortelli burro e salvia',
      description: 'Pasta fresca artigianale Piacentina',
      price: 10,
      available: true,
      _id: '234565437734',
    },
  ])
  return (
    <div className="dashboard-container">
      <div className="column">
        <TitleCard title="Dashboard" subtitle={`Tieni tutto sott'occhio!`} />
      </div>
      <div className="column-wrap">
        <div className="column">
          {/* COLONNA SINISTRA */}

          <div className="column-wrap">
            <NumberCard number={4} name="Menù caricati" />
            <NumberCard number={5} name="Clienti attivi" />
            <NumberCard number={23} name="Ordini ricevuti" />
          </div>
          <div className="column-wrap">
            <div className="column-100">
              {menu.map((el, key) => (
                <div key={key} className="col-element">
                  <MenuOptionStatus option={el} />
                </div>
              ))}
            </div>
          </div>

          {/* FINE COLONNA SINISTRA */}
        </div>
        <div className="column">
          {/* COLONNA DESTRA */}

          <div className="column-wrap">
            <div className="column-100">
              {customers.map((el, key) => (
                <div key={key} className="col-element">
                  <CustomerOrderStatus customer={el} />
                </div>
              ))}
            </div>
          </div>

          {/* FINE COLONNA DESTRA */}
        </div>
      </div>
    </div>
  )
}

export default Dashboard

import {
  faClose,
  faEye,
  faPenToSquare,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { Link } from 'react-router-dom'

function MenuOptionStatus(props) {
  return (
    <div className="card order-status-card">
      <div className="card-row">
        <div className="card-col">
          <p className="card-title">{props.option.name || 'Menu'}</p>
          <p className="card-subtitle2">
            {props.option.description || 'vuoto'}
          </p>
          <p className="card-subtitle">
            {props.option.categories?.length || 0} sezioni
          </p>
        </div>
        <div className="card-row-right">
          {/* <div
            className={`card-button card-button-${
              props.option.available ? 'secondary' : 'primary'
            }`}
          >
            {props.option.available ? 'Rimuovi disp.' : 'Rendi disp.'}
          </div> */}
          <Link to={`/menu/${props.option._id}`} className="edit-link">
            <FontAwesomeIcon icon={faPenToSquare} />
          </Link>
          <div
            className="edit-link"
            onClick={() => props.toggleHidden(props.option)}
          >
            <FontAwesomeIcon icon={props.option?.hidden ? faEye : faClose} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default MenuOptionStatus

import React, { useEffect, useState } from 'react'
import TitleCard from '../components/TitleCard'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUpload } from '@fortawesome/free-solid-svg-icons'

import Select from 'react-select'

import { useParams, useHistory } from 'react-router-dom'

const types = [
  { value: 'producer', label: 'Producer' },
  { value: 'single', label: 'Singolo' },
  { value: 'admin', label: 'Admin' },
]

function UserNew(props) {
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [type, setType] = useState({ value: 'single', label: 'Singolo' })
  const [customer, setCustomer] = useState(null)
  const [enabled, setEnambled] = useState(false)
  const [customers, setCustomers] = useState([])
  const { id } = useParams()
  const history = useHistory()
  useEffect(() => {
    if (id !== 'new') {
      var myHeaders = new Headers()
      myHeaders.append('Content-Type', 'application/json')
      myHeaders.append(
        'x-auth-token',
        localStorage.getItem('cut-catering-token'),
      )

      var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow',
      }

      fetch('https://app.cutcatering.it/api/users/' + id, requestOptions)
        .then(response => response.json())
        .then(result => {
          console.log(result)
          setName(result.name)
          setEmail(result.email)
          setPassword('')
          setType(getType(result.type))
          setCustomer({ label: result.customer, value: result.customerId })
          setEnambled(result.enabled)
        })
        .catch(error => console.log('error', error))
    }
  }, [id])

  useEffect(() => {
    var myHeaders = new Headers()
    myHeaders.append('Content-Type', 'application/json')
    myHeaders.append('x-auth-token', localStorage.getItem('cut-catering-token'))

    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow',
    }

    fetch('https://app.cutcatering.it/api/customers/', requestOptions)
      .then(response => response.json())
      .then(result => {
        console.log(result)
        setCustomers(result)
      })
      .catch(error => console.log('error', error))
  }, [])

  return (
    <div className="dashboard-container">
      <div className="column">
        <TitleCard
          title="Nuovo utente"
          subtitle={`Inserisci le informazioni richieste e seleziona il cliente associato!`}
        />
      </div>
      <div className="toggle-input-row">
        <span className="nome-title-sec">Nome</span>
        <input
          type="text"
          value={name}
          onChange={e => setName(e.target.value)}
        />
      </div>
      <div className="toggle-input-row">
        <span className="nome-title-sec">Mail</span>
        <input
          type="text"
          value={email}
          onChange={e => setEmail(e.target.value)}
        />
      </div>
      <div className="toggle-input-row">
        <span className="nome-title-sec">Password</span>
        <input
          type="text"
          value={password}
          onChange={e => setPassword(e.target.value)}
        />
      </div>
      <div className="toggle-input-row">
        <span className="nome-title-sec">Tipo</span>
        <Select
          value={type}
          onChange={e => {
            setType(e)
          }}
          options={types}
        />
      </div>

      <div className="new-allergene-item">
        <span className="nome-title-sec">Cliente</span>
        <Select
          value={customer}
          onChange={e => {
            setCustomer(e)
          }}
          options={customers.map(m => ({ label: m.name, value: m._id }))}
        />
      </div>

      {id !== 'new' ? (
        <div className="header-add">
          <div
            className="card-button card-button-primary"
            onClick={() => {
              var myHeaders = new Headers()
              myHeaders.append('Content-Type', 'application/json')
              myHeaders.append(
                'x-auth-token',
                localStorage.getItem('cut-catering-token'),
              )

              fetch('https://app.cutcatering.it/api/users/' + id, {
                method: 'POST',
                headers: myHeaders,
                body: JSON.stringify({
                  name,
                  email,
                  password,
                  customer: customer.label,
                  customerId: customer.value,
                  type: type.value,
                  enabled,
                }),
                redirect: 'follow',
              })
                .then(response => response.text())
                .then(result => {
                  console.log(result)
                  history.push('/users')
                }) //()
                .catch(error => console.log('error', error))
            }}
          >
            <FontAwesomeIcon icon={faUpload} /> Modifica
          </div>
          <div
            className="card-button card-button-primary"
            onClick={() => {
              var myHeaders = new Headers()
              myHeaders.append('Content-Type', 'application/json')
              myHeaders.append(
                'x-auth-token',
                localStorage.getItem('cut-catering-token'),
              )

              fetch('https://app.cutcatering.it/api/users/', {
                method: 'POST',
                headers: myHeaders,
                body: JSON.stringify({
                  name,
                  vat: email,
                  address: password,
                  customer: customer.label,
                  customerId: customer.value,
                  type: type.value,
                  enabled,
                }),
                redirect: 'follow',
              })
                .then(response => response.text())
                .then(result => {
                  console.log(result)
                  history.push('/users')
                }) //()
                .catch(error => console.log('error', error))
            }}
          >
            <FontAwesomeIcon icon={faUpload} /> Salva come nuovo
          </div>
        </div>
      ) : (
        <div className="header-add">
          <div
            className="card-button card-button-primary"
            onClick={() => {
              var myHeaders = new Headers()
              myHeaders.append('Content-Type', 'application/json')
              myHeaders.append(
                'x-auth-token',
                localStorage.getItem('cut-catering-token'),
              )

              fetch('https://app.cutcatering.it/api/users', {
                method: 'POST',
                headers: myHeaders,
                body: JSON.stringify({
                  name,
                  email: email,
                  password: password,
                  customer: customer.label,
                  customerId: customer.value,
                  type: type.value,
                  enabled,
                }),
                redirect: 'follow',
              })
                .then(response => response.text())
                .then(result => {
                  console.log(result)
                  history.push('/users')
                }) //()
                .catch(error => console.log('error', error))
            }}
          >
            <FontAwesomeIcon icon={faUpload} /> SALVA
          </div>
        </div>
      )}
    </div>
  )
}

function getType(code) {
  if (code === 'producer') return { value: 'producer', label: 'Producer' }
  if (code === 'single') return { value: 'single', label: 'Singolo' }
  if (code === 'admin') return { value: 'admin', label: 'Admin' }
}

export default UserNew

import React from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash, faUser, faUsers } from '@fortawesome/free-solid-svg-icons'

function OrderListItem(props) {
  return (
    <div className="card order-status-card">
      <div className="card-row">
        <div className="card-col col-w-140">
          {props.setDeleted ? (
            <p className="card-title">{props.order.customer || 'Cliente'}</p>
          ) : null}
          <p className="card-subtitle">
            <FontAwesomeIcon
              icon={props.order.type === 'producer' ? faUsers : faUser}
            />{' '}
            {props.order.name || 'Name'}
          </p>
          <p className="card-order-date">{formatTime(props.order.time)}</p>
        </div>
        <div className="card-col col-w100">
          {props.order.items.map((el, key) => (
            <p key={key} className="order-item-element">
              <span className="order-item-count">{el.quantity}</span> x{' '}
              {el.name}
            </p>
          ))}
        </div>
        {props.setDeleted ? (
          <div
            className="edit-link"
            onClick={() => {
              var myHeaders = new Headers()
              myHeaders.append('Content-Type', 'application/json')
              myHeaders.append(
                'x-auth-token',
                localStorage.getItem('cut-catering-token'),
              )

              fetch(
                'https://app.cutcatering.it/api/orders/' + props.order._id,
                {
                  method: 'DELETE',
                  headers: myHeaders,
                  redirect: 'follow',
                },
              )
                .then(response => response.text())
                .then(result => {
                  console.log(result)
                  props.setDeleted(result)
                }) //()
                .catch(error => console.log('error', error))
            }}
          >
            <FontAwesomeIcon icon={faTrash} />
          </div>
        ) : null}
      </div>
    </div>
  )
}

function formatTime(time) {
  let yourDate = new Date(time)
  const offset = yourDate.getTimezoneOffset()
  yourDate = new Date(yourDate.getTime() - offset * 60 * 1000)
  return yourDate.toISOString().split('.')[0].split('T').join(' ')
}

export default OrderListItem

import React from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCopy,
  faPenToSquare,
  faRefresh,
} from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom'
import copy from 'copy-to-clipboard'

function CustomerOrderStatus(props) {
  return (
    <div className="card order-status-card">
      <div className="card-row">
        <div className="card-col">
          <p className="card-title">{props.customer.name || 'Cliente'}</p>
          <p className="card-subtitle">{props.customer.orders || 0} porzioni</p>
        </div>
        <div className="card-row-right column-gap-12">
          <div
            className={`card-button card-button-primary`}
            onClick={() => {
              copy(
                'https://app.cutcatering.it/order/' + props.customer.singleId,
              )
            }}
          >
            <FontAwesomeIcon icon={faCopy} /> Single
          </div>
          <div
            className={`card-button card-button-primary`}
            onClick={() => {
              copy(
                'https://app.cutcatering.it/order/' + props.customer.producerId,
              )
            }}
          >
            <FontAwesomeIcon icon={faCopy} /> Producer
          </div>
          <div
            className={`card-button card-button-primary`}
            onClick={() => {
              var myHeaders = new Headers()
              myHeaders.append('Content-Type', 'application/json')
              myHeaders.append(
                'x-auth-token',
                localStorage.getItem('cut-catering-token'),
              )

              fetch(
                'https://app.cutcatering.it/api/customers/' +
                  props.customer._id +
                  '/renewIds',
                {
                  method: 'POST',
                  headers: myHeaders,
                  body: JSON.stringify({
                    single: true,
                    producer: true,
                  }),
                  redirect: 'follow',
                },
              )
                .then(response => response.text())
                .then(result => {
                  console.log(result)
                  props.setChange(result)
                }) //()
                .catch(error => console.log('error', error))
            }}
          >
            <FontAwesomeIcon icon={faRefresh} /> Rigenera
          </div>
          <div
            className={`card-button card-button-${
              props.customer.enabled ? 'secondary' : 'primary'
            }`}
            onClick={() => {
              var myHeaders = new Headers()
              myHeaders.append('Content-Type', 'application/json')
              myHeaders.append(
                'x-auth-token',
                localStorage.getItem('cut-catering-token'),
              )

              fetch(
                'https://app.cutcatering.it/api/customers/' +
                  props.customer._id,
                {
                  method: 'POST',
                  headers: myHeaders,
                  body: JSON.stringify({
                    enabled: !props.customer.enabled,
                  }),
                  redirect: 'follow',
                },
              )
                .then(response => response.text())
                .then(result => {
                  console.log(result)
                  props.setChange(result)
                }) //()
                .catch(error => console.log('error', error))
            }}
          >
            {props.customer.enabled ? 'Chiudi ordini' : 'Apri ordini'}
          </div>

          <Link to={`/customers/${props.customer._id}`} className="edit-link">
            <FontAwesomeIcon icon={faPenToSquare} />
          </Link>
        </div>
      </div>
    </div>
  )
}

export default CustomerOrderStatus

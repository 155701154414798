import React, { useEffect, useState } from 'react'
import {
  BrowserRouter,
  Route,
  NavLink,
  Switch,
  Redirect,
} from 'react-router-dom'
import jwt_decode from 'jwt-decode'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faBagShopping,
  faBars,
  faFireBurner,
  faRightFromBracket,
  faUser,
  faUsers,
} from '@fortawesome/free-solid-svg-icons'

import Settings from './pages/Settings'

import Login from './pages/Login'

import logo from './img/logo-white.png'
import './App.css'
import Dashboard from './pages/Dashboard'
import ComponentWebsocket from './pages/ComponentWebsocket'
import Customers from './pages/Customers'
import Orders from './pages/Orders'
import Menu from './pages/Menu'
import Order from './pages/Order'
import MenuNew from './pages/MenuNew'
import CustomerNew from './pages/CustomerNew'
import Users from './pages/Users'
import UserNew from './pages/UserNew'
import PasswordReset from './pages/PasswordReset'

const AuthRoute = props => {
  const { type, user } = props
  if (type === 'guest' && user) return <Redirect to="/" />
  if (type === 'customer' && !user) return <Redirect to="/login" />
  if (type === 'private' && !user) return <Redirect to="/login" />
  if (type === 'private' && user.type !== 'admin')
    return <Redirect to="/order" />
  if (type === 'customer' && user.type === 'admin') return <Redirect to="/" />

  return <Route {...props} />
}
let usrTemp = null
try {
  const token = localStorage.getItem('cut-catering-token')
  if (token) usrTemp = jwt_decode(token)
} catch (e) {
  console.log(e.message)
}

function App() {
  let [user, setUser] = useState(usrTemp)
  let [sideBar, setSideBar] = useState(true)

  useEffect(() => {
    try {
      const token = localStorage.getItem('cut-catering-token')
      if (token) setUser(jwt_decode(token))
    } catch (e) {
      console.log(e.message)
    }
  }, [])

  let logout = () => {
    localStorage.removeItem('cut-catering-token')
    setUser(null)
  }
  return (
    <BrowserRouter>
      <>
        {false ? (
          <header className="navbar navbar-dark">
            <div className="nav-item">
              <div className="nav-link" onClick={() => setSideBar(!sideBar)}>
                <i class="fas fa-bars navbar-icon"></i>
              </div>
            </div>
            <div className="nav-item">
              <img src={logo} className="logo-small" alt="logo app"></img>
            </div>
            <div className="nav-item">
              <div className="nav-link" onClick={() => logout()}>
                <i class="fas fa-sign-out-alt navbar-icon"></i>
              </div>
            </div>
          </header>
        ) : null}
        {sideBar && user?.type === 'admin' ? (
          <div id="sidebarMenu" className="sidebar">
            <div className="nav-item">
              <NavLink
                to="/dashboard"
                className="nav-link"
                activeClassName="active"
              >
                <img src={logo} className="logo-sidebar" alt="link home"></img>
              </NavLink>
              <NavLink
                to="/orders"
                className="nav-link"
                activeClassName="active"
              >
                <FontAwesomeIcon icon={faBagShopping} />
              </NavLink>
              <NavLink
                to="/customers"
                className="nav-link"
                activeClassName="active"
              >
                <FontAwesomeIcon icon={faUsers} />
              </NavLink>
              <NavLink to="/menu" className="nav-link" activeClassName="active">
                <FontAwesomeIcon icon={faBars} />
              </NavLink>
              <NavLink
                to="/users"
                className="nav-link"
                activeClassName="active"
              >
                <FontAwesomeIcon icon={faUser} />
              </NavLink>
              <NavLink
                to="/wss-component"
                className="nav-link"
                activeClassName="active"
              >
                <FontAwesomeIcon icon={faFireBurner} />
              </NavLink>
            </div>
            <div className="nav-link" onClick={() => logout()}>
              <FontAwesomeIcon icon={faRightFromBracket} />
            </div>
          </div>
        ) : null}
        <main
          id="main-appplication"
          className={
            sideBar && user?.type === 'admin'
              ? 'main-side-opened'
              : 'main-side-closed'
          }
        >
          <Switch>
            <Route path="/password-reset/:id/:token">
              <PasswordReset />
            </Route>
            <Route path="/order/:id">
              <Order setUser={setUser} user={user} />
            </Route>
            <AuthRoute
              path="/order/"
              user={user}
              setUser={setUser}
              type="customer"
            >
              <Order setUser={setUser} user={user} />
            </AuthRoute>

            <AuthRoute path="/login" type="guest" user={user}>
              <Login setUser={setUser} />
            </AuthRoute>

            <AuthRoute
              path="/settings"
              component={Settings}
              type="private"
              user={user}
            />
            <AuthRoute
              path="/users/:id"
              component={UserNew}
              type="private"
              user={user}
            />
            <AuthRoute
              path="/users/"
              component={Users}
              type="private"
              user={user}
            />
            <AuthRoute
              path="/customers/:id"
              component={CustomerNew}
              type="private"
              user={user}
            />
            <AuthRoute
              path="/customers"
              component={Customers}
              type="private"
              user={user}
            />
            <AuthRoute
              path="/orders"
              component={Orders}
              type="private"
              user={user}
            />
            <AuthRoute
              path="/menu/:id"
              component={MenuNew}
              type="private"
              user={user}
            />
            <AuthRoute
              path="/menu"
              component={Menu}
              type="private"
              user={user}
            />
            <AuthRoute
              path="/wss-component"
              component={ComponentWebsocket}
              type="private"
              user={user}
            />
            <AuthRoute
              path="/dashboard"
              component={Dashboard}
              type="private"
              user={user}
            />

            <Route
              path="/"
              render={({ location }) => (
                <Redirect
                  to={{
                    pathname: '/dashboard',
                    state: { from: location },
                  }}
                />
              )}
            />
          </Switch>
        </main>
      </>
    </BrowserRouter>
  )
}

export default App

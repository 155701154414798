import React, { useEffect, useState } from 'react'
import TitleCard from '../components/TitleCard'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faUpload } from '@fortawesome/free-solid-svg-icons'

import Select from 'react-select'

import { useHistory, useParams } from 'react-router-dom'

//     name: String,
//     vat: String,
//     address: String,
//     menu: String,
//     deliveryTime: Date,
//     closure: Date,
//     price: Number,
//     enabled: Boolean,

const weekdays = [
  { label: 'Dom', value: 0 },
  { label: 'Lun', value: 1 },
  { label: 'Mar', value: 2 },
  { label: 'Mer', value: 3 },
  { label: 'Gio', value: 4 },
  { label: 'Ven', value: 5 },
  { label: 'Sab', value: 6 },
]

function CustomerNew(props) {
  const [name, setName] = useState('')
  const [vat, setVat] = useState('')
  const [address, setAddress] = useState('')
  const [deliveryTime, setDeliveryTime] = useState(new Date())
  const [closure, setClosure] = useState(new Date())
  const [price, setPrice] = useState(1500)
  const [enabled, setEnambled] = useState(false)
  const [hasSchedule, setHasSchedule] = useState(false)
  const [schedule, setSchedule] = useState([])

  const [menu, setMenu] = useState({ label: '', value: '' })
  const [menus, setMenus] = useState([])
  const { id } = useParams()
  const history = useHistory()
  useEffect(() => {
    if (id !== 'new') {
      var myHeaders = new Headers()
      myHeaders.append('Content-Type', 'application/json')
      myHeaders.append(
        'x-auth-token',
        localStorage.getItem('cut-catering-token'),
      )

      var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow',
      }

      fetch('https://app.cutcatering.it/api/customers/' + id, requestOptions)
        .then(response => response.json())
        .then(result => {
          console.log(result)
          setName(result.name)
          setVat(result.vat)
          setAddress(result.address)
          setDeliveryTime(new Date(result?.deliveryTime))
          setClosure(new Date(result.closure))
          setPrice(result.price)
          setEnambled(result.enabled)
          setHasSchedule(result.hasSchedule || false)
          setMenu({ label: result.menuName, value: result.menu })
          setSchedule(result.schedule)
        })
        .catch(error => console.log('error', error))
    }
  }, [id])

  useEffect(() => {
    var myHeaders = new Headers()
    myHeaders.append('Content-Type', 'application/json')
    myHeaders.append('x-auth-token', localStorage.getItem('cut-catering-token'))

    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow',
    }

    fetch('https://app.cutcatering.it/api/menus/', requestOptions)
      .then(response => response.json())
      .then(result => {
        console.log(result)
        setMenus(
          result
            .filter(e => !e?.hidden)
            .map(m => ({ label: m.name, value: m._id })),
        )
      })
      .catch(error => console.log('error', error))
  }, [])

  const toggleSchedule = () => {
    if (!hasSchedule && !schedule.length)
      setSchedule([
        {
          menu: '',
          menuName: '',
          deliveryD: 1, // numero giorno settimana
          deliveryH: 13, // ore
          deliveryM: 0, // minuti
          openingHoursBefore: 20,
          closingHoursBefore: 4,
        },
      ])
    setHasSchedule(!hasSchedule)
  }

  return (
    <div className="dashboard-container">
      <div className="column">
        <TitleCard
          title={id !== 'new' ? `Cliente: ${name}` : 'Nuovo cliente'}
          subtitle={`Inserisci le informazioni richieste e seleziona il menu!`}
        />
      </div>
      <div className="new-menu-card">
        <div className="toggle-input-row">
          <span className="nome-title-sec">Nome</span>
          <input
            type="text"
            value={name}
            onChange={e => setName(e.target.value)}
          />
        </div>
        <div className="toggle-input-row">
          <span className="nome-title-sec">VAT</span>
          <input
            type="text"
            value={vat}
            onChange={e => setVat(e.target.value)}
          />
        </div>
        <div className="toggle-input-row">
          <span className="nome-title-sec">Indirizzo</span>
          <input
            type="text"
            value={address}
            onChange={e => setAddress(e.target.value)}
          />
        </div>
        <div className="toggle-input-row">
          <span className="nome-title-sec">Prezzo</span>
          <input
            type="number"
            value={price}
            onChange={e => setPrice(e.target.value)}
          />
        </div>
        <div className="toggle-input-row">
          <span className="nome-title-sec">Ripetitivo</span>
          <input
            type="checkbox"
            checked={hasSchedule}
            onChange={e => toggleSchedule()}
          />
        </div>
        {hasSchedule ? (
          <div className="schedule-container">
            {schedule.map((day, key) => (
              <div key={key} className="schedule-day-box">
                <div className="schedule-day-dropdown menu-inline-selection">
                  <span className="section-title-span">Giorno: </span>
                  <span className="section-select-span-small">
                    <Select
                      value={weekdays[day.deliveryD]}
                      onChange={e => {
                        day.deliveryD = e.value
                        setSchedule([...schedule])
                      }}
                      options={weekdays}
                    />
                  </span>
                </div>

                <div className="new-allergene-item menu-inline-selection">
                  <span className="section-title-span">Menu: </span>
                  <span className="section-select-span">
                    <Select
                      value={{ label: day.menuName, value: day.menu }}
                      onChange={e => {
                        day.menu = e.value
                        day.menuName = e.label
                        setSchedule([...schedule])
                      }}
                      options={menus}
                    />
                  </span>
                </div>

                <div className="schedule-hour-dropdown menu-inline-selection">
                  <span className="section-title-span">Ora: </span>
                  <input
                    type="time"
                    min="07:00"
                    max="23:00"
                    value={formatTime(day)}
                    onChange={e => {
                      let date = e.target.value.split(':')
                      day.deliveryH = Number.parseInt(date[0])
                      day.deliveryM = Number.parseInt(date[1])
                      setSchedule([...schedule])
                    }}
                  />
                </div>

                <div className="schedule-open-dropdown menu-inline-selection">
                  <span className="section-title-span">Apre: </span>
                  <input
                    type="number"
                    min="0"
                    max="50"
                    value={day.openingHoursBefore}
                    onChange={e => {
                      day.openingHoursBefore = Number.parseInt(e.target.value)
                      setSchedule([...schedule])
                    }}
                  />
                </div>

                <div className="schedule-open-dropdown menu-inline-selection">
                  <span className="section-title-span">Chiude: </span>
                  <input
                    type="number"
                    min="0"
                    max="50"
                    value={day.closingHoursBefore}
                    onChange={e => {
                      day.closingHoursBefore = Number.parseInt(e.target.value)
                      setSchedule([...schedule])
                    }}
                  />
                </div>

                <span
                  className="options-menu-allerg-section-remove"
                  onClick={() => {
                    schedule.splice(key, 1)
                    setSchedule([...schedule])
                  }}
                >
                  X
                </span>
              </div>
            ))}
            <div
              className="add-new-day-schedule"
              onClick={() => {
                if (!schedule.length) {
                  setSchedule([
                    {
                      menu: '',
                      menuName: '',
                      deliveryD: 1, // numero giorno settimana
                      deliveryH: 13, // ore
                      deliveryM: 0, // minuti
                      openingHoursBefore: 20,
                      closingHoursBefore: 4,
                    },
                  ])
                } else {
                  const lastDay = schedule[schedule.length - 1]
                  let newDeliveryD = lastDay.deliveryD + 1
                  if (newDeliveryD > 6) newDeliveryD = 6
                  schedule.push({ ...lastDay, deliveryD: newDeliveryD })
                  setSchedule([...schedule])
                }
              }}
            >
              <FontAwesomeIcon icon={faPlus} /> Aggiungi Giorno
            </div>
          </div>
        ) : (
          <>
            <div className="toggle-input-row">
              <span className="nome-title-sec">Momento consegna</span>
              <input
                type="datetime-local"
                value={getTrimmedDate(deliveryTime)}
                onChange={e => setDeliveryTime(getNewDate(e.target.value))}
              />
            </div>
            <div className="toggle-input-row">
              <span className="nome-title-sec">Momento chiusura ordini</span>
              <input
                type="datetime-local"
                value={getTrimmedDate(closure)}
                onChange={e => {
                  let newDate = getNewDate(e.target.value)
                  setClosure(newDate)
                  let deliveryTime2 = new Date(deliveryTime)
                  deliveryTime2.setFullYear(newDate.getFullYear())
                  deliveryTime2.setMonth(newDate.getMonth())
                  deliveryTime2.setDate(newDate.getDate())
                  setDeliveryTime(deliveryTime2)
                }}
              />
            </div>

            <div className="new-allergene-item menu-inline-selection">
              <span className="section-title-span">Menu: </span>
              <span className="section-select-span">
                <Select
                  value={menu}
                  onChange={e => {
                    setMenu(e)
                  }}
                  options={menus}
                />
              </span>
            </div>
          </>
        )}
        {id !== 'new' ? (
          <div className="header-add">
            <div
              className="card-button card-button-primary"
              onClick={() => {
                var myHeaders = new Headers()
                myHeaders.append('Content-Type', 'application/json')
                myHeaders.append(
                  'x-auth-token',
                  localStorage.getItem('cut-catering-token'),
                )

                fetch('https://app.cutcatering.it/api/customers/' + id, {
                  method: 'POST',
                  headers: myHeaders,
                  body: JSON.stringify({
                    name,
                    vat,
                    address,
                    menu: menu.value,
                    menuName: menu.label,
                    deliveryTime,
                    closure,
                    price,
                    enabled,
                    hasSchedule,
                    schedule,
                  }),
                  redirect: 'follow',
                })
                  .then(response => response.text())
                  .then(result => {
                    console.log(result)
                    history.push('/customers')
                  }) //()
                  .catch(error => console.log('error', error))
              }}
            >
              <FontAwesomeIcon icon={faUpload} /> Modifica
            </div>
            <div
              className="card-button card-button-primary"
              onClick={() => {
                var myHeaders = new Headers()
                myHeaders.append('Content-Type', 'application/json')
                myHeaders.append(
                  'x-auth-token',
                  localStorage.getItem('cut-catering-token'),
                )

                fetch('https://app.cutcatering.it/api/customers/', {
                  method: 'POST',
                  headers: myHeaders,
                  body: JSON.stringify({
                    name,
                    vat,
                    address,
                    menu: menu.value,
                    menuName: menu.label,
                    deliveryTime,
                    closure,
                    price,
                    enabled,
                    hasSchedule,
                    schedule,
                  }),
                  redirect: 'follow',
                })
                  .then(response => response.text())
                  .then(result => {
                    console.log(result)
                    history.push('/customers')
                  }) //()
                  .catch(error => console.log('error', error))
              }}
            >
              <FontAwesomeIcon icon={faUpload} /> Salva come nuovo
            </div>
          </div>
        ) : (
          <div className="header-add">
            <div
              className="card-button card-button-primary"
              onClick={() => {
                var myHeaders = new Headers()
                myHeaders.append('Content-Type', 'application/json')
                myHeaders.append(
                  'x-auth-token',
                  localStorage.getItem('cut-catering-token'),
                )

                fetch('https://app.cutcatering.it/api/customers', {
                  method: 'POST',
                  headers: myHeaders,
                  body: JSON.stringify({
                    name,
                    vat,
                    address,
                    menu: menu.value,
                    menuName: menu.label,
                    deliveryTime,
                    closure,
                    price,
                    enabled,
                    hasSchedule,
                    schedule,
                  }),
                  redirect: 'follow',
                })
                  .then(response => response.text())
                  .then(result => {
                    console.log(result)
                    history.push('/customers')
                  }) //()
                  .catch(error => console.log('error', error))
              }}
            >
              <FontAwesomeIcon icon={faUpload} /> SALVA
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

function getTrimmedDate(date) {
  let date3 = new Date(date)
  let date2 = new Date()
  date3.setMinutes(-date2.getTimezoneOffset())
  let date1 = date3.toISOString().split(':')
  date1.splice(-1)
  return date1.join(':')
}

function getNewDate(date) {
  let date3 = new Date(date + ':00.000Z')
  let date2 = new Date()
  date3.setMinutes(date2.getTimezoneOffset())
  return date3
}

function formatTime(day) {
  return (
    (day.deliveryH > 9 ? day.deliveryH : '0' + day.deliveryH) +
    ':' +
    (day.deliveryM > 9 ? day.deliveryM : '0' + day.deliveryM)
  )
}
export default CustomerNew

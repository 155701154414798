import React, { useEffect, useState } from 'react'
import { faClose, faList, faPrint } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import OrderListItem from '../components/OrderListItem'

function ComponentWebsocket(props) {
  const [data, setData] = useState(null)
  const [status, setStatus] = useState('disconnected')
  const [comande, setComande] = useState([])
  const [comandeObj, setComandeObj] = useState({})
  const [print, setPrint] = useState('')
  useEffect(() => {
    let wssUrl = `wss://app.cutcatering.it/api/kitchen?token=${localStorage.getItem(
      'cut-catering-token',
    )}`

    let parser = event => {
      try {
        let body = JSON.parse(event.data)
        console.log(body)
        if (body) setData(body)
      } catch (e) {}
    }

    function onOpen() {
      setStatus('connected')
    }

    function onClose() {
      setStatus('disconnected')
      setTimeout(() => {
        if (window.ws === 'disconnect') return
        setStatus('connecting')
        window.ws = new WebSocket(wssUrl)
        window.ws.onmessage = parser
        window.ws.onopen = onOpen
        window.ws.onclose = onClose
      }, Math.round(Math.random() * 2000))
    }

    setStatus('connecting')
    window.ws = new WebSocket(wssUrl)
    window.ws.onmessage = parser
    window.ws.onopen = onOpen
    window.ws.onclose = onClose

    return () => {
      if (window.ws) {
        try {
          window.ws.onopen = () => {}
          window.ws.onclose = () => {}
          window.ws.close()
          window.ws = 'disconnect'
        } catch (e) {
          console.log(e.message || e)
        }
      }
    }
  }, [])

  useEffect(() => {
    let customers = {}
    let inCustomers = {}
    data?.customers?.forEach(cust => (inCustomers[cust._id] = cust))
    data?.orders?.forEach(order => {
      if (!customers[order.customerId]) {
        customers[order.customerId] = {
          id: order.customerId,
          name: order.customer,
          menu: order.menu,
          deliveryTime: parseDate(inCustomers[order.customerId].deliveryTime),
          orderedItems: order.items.map((e, key) => ({
            ...e,
            menuId: order.menuId,
          })),
        }
      } else {
        order.items.forEach(currentItem => {
          let found = false
          customers[order.customerId].orderedItems.forEach(menuItem => {
            if (
              menuItem.id === currentItem.id &&
              menuItem.name === currentItem.name &&
              menuItem.menuId === order.menuId &&
              currentItem.id
            ) {
              found = true
              menuItem.quantity += currentItem.quantity
            }
          })
          if (!found)
            customers[order.customerId].orderedItems.push({
              ...currentItem,
              menuId: order.menuId,
            })
        })
      }
    })

    setComande(Object.values(customers))
    setComandeObj(customers)
  }, [data])

  if (print)
    return (
      <>
        <iframe
          title="Ordini Cut Catering"
          id="ifmcontentstoprint"
          style={{ height: 0, width: 0, position: 'absolute' }}
        ></iframe>
        <div className={`wss-status wss-status-${status}`}></div>
        <div className="close-print-box" onClick={() => setPrint('')}>
          <FontAwesomeIcon icon={faClose} />
        </div>

        <div className="send-print-box" onClick={() => printPage()}>
          <FontAwesomeIcon icon={faPrint} />
        </div>

        <div id="toprint">
          <div className="comanda-print-container">
            <div className="comanda-titlebar">
              <div className="comanda-title">{comandeObj[print].name}</div>
              <div className="comanda-hour">
                <div className="hour-title">Orario consegna</div>
                <div className="hour-time">
                  {comandeObj[print]?.deliveryTime}
                </div>
              </div>
            </div>
            <div className="comanda-menu">
              <FontAwesomeIcon icon={faList} /> {comandeObj[print].menu}
            </div>

            <div className="comanda-products">
              <div className="print-orders-title">TOTALE</div>
              {comandeObj[print].orderedItems.map((el, key) => (
                <div key={key} className="product-in-preparation">
                  <span className="order-item-count">{el.quantity}</span>{' '}
                  {el.name}
                </div>
              ))}
            </div>

            <div className="orders-list-print-container">
              {data.orders.map((el, key) =>
                comandeObj[print].id === el.customerId ? (
                  <div key={key} className="order-item-print">
                    <OrderListItem order={el} />
                  </div>
                ) : null,
              )}
            </div>
          </div>
        </div>
      </>
    )

  return (
    <>
      <div className={`wss-status wss-status-${status}`}></div>
      {/* <div className="preparation-section">
        <div className="title-in-preparation">IN PREPARAZIONE: </div>
        {elInPreparation.map((el, key) => (
          <div key={key} className="product-in-preparation">
            <span className="order-item-count">{el.quantity}</span> {el.name}
          </div>
        ))}
      </div> */}
      <div className="comande-container">
        {comande.map((customer, key) => (
          <div className={`comanda-box ${customer?.status}`} key={key}>
            <div className="comanda-titlebar">
              <div className="comanda-title">{customer?.name}</div>
              <div className="comanda-hour">{customer?.deliveryTime}</div>
            </div>
            <div className="comanda-menu">
              <FontAwesomeIcon icon={faList} /> {customer?.menu}
            </div>

            <div className="comanda-products">
              {customer.orderedItems.map((el, key) => (
                <div key={key} className="product-in-preparation">
                  <span className="order-item-count">{el.quantity}</span>{' '}
                  {el.name}
                </div>
              ))}
            </div>
            <div className="actions">
              <div
                className="card-button card-button-inverted"
                onClick={() => setPrint(customer.id)}
              >
                <FontAwesomeIcon icon={faPrint} /> Stampa
              </div>
            </div>
          </div>
        ))}
      </div>
    </>
  )
}

function parseDate(date) {
  let newDate = new Date(date)
  return `${newDate.getHours()}:${
    newDate.getMinutes() > 9 ? newDate.getMinutes() : '0' + newDate.getMinutes()
  }`
}

function printPage() {
  var content = document.getElementById('toprint')
  var pri = document.getElementById('ifmcontentstoprint').contentWindow

  pri.document.open()
  pri.document.write(content.innerHTML)
  pri.document.head.innerHTML = document.head.innerHTML
  setTimeout(() => {
    pri.document.close()
    setTimeout(() => {
      pri.focus()
      pri.print()
    }, 1200)
  }, 1200)
}

export default ComponentWebsocket

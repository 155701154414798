import React from 'react'
import { Link } from 'react-router-dom'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDownload, faPlus } from '@fortawesome/free-solid-svg-icons'

function FiltersAddBarOrders(props) {
  return (
    <div className="filters-add-row">
      <div className="filters-add-col">
        <div className="filter-box">
          <input
            type="text"
            placeholder="Cerca..."
            value={props.search}
            onChange={e => props.setSearch(e.target.value)}
          />
        </div>
        <div className="filter-box">
          <label htmlFor="stato">Stato: </label>

          <select
            name="stato"
            id="stato"
            value={props.filterParams.filter}
            onChange={e =>
              props.setFilterParams({
                ...props.filterParams,
                filter: e.target.value,
              })
            }
          >
            {props.filterOptions.map((el, key) => (
              <option key={key} value={el.value}>
                {el.label}
              </option>
            ))}
          </select>
        </div>
        {props.filterParams.filter === 'range' ? (
          <>
            <div className="filter-box">
              <label htmlFor="stato">Inizio: </label>

              <input
                type="date"
                value={getTrimmedDate(props.filterParams.start)}
                onChange={e =>
                  props.setFilterParams({
                    ...props.filterParams,
                    start: new Date(e.target.value),
                  })
                }
              />
            </div>
            <div className="filter-box">
              <label htmlFor="stato">Fine: </label>
              <input
                type="date"
                value={getTrimmedDate(props.filterParams.end)}
                onChange={e =>
                  props.setFilterParams({
                    ...props.filterParams,
                    end: new Date(e.target.value),
                  })
                }
              />
            </div>
          </>
        ) : null}
      </div>
      <div className="filters-add-col">
        <Link to={props.newPath}>
          <div className="card-button card-button-primary">
            <FontAwesomeIcon icon={faPlus} /> Nuovo
          </div>
        </Link>
        <div
          className="card-button card-button-primary"
          onClick={() => props.downloadBtn()}
        >
          <FontAwesomeIcon icon={faDownload} /> CSV
        </div>
      </div>
    </div>
  )
}

function getTrimmedDate(date) {
  let date1 = new Date(date)
  let month = date1.getMonth() + 1
  return `${date1.getFullYear()}-${month > 9 ? month : '0' + month}-${
    date1.getDate() > 9 ? date1.getDate() : '0' + date1.getDate()
  }`
}

export default FiltersAddBarOrders

import React, { useEffect, useState } from 'react'
import TitleCard from '../components/TitleCard'
import FiltersAddBar from '../components/FiltersAddBar'
import UserListItem from '../components/UserListItem'

function Users(props) {
  const [search, setSearch] = useState('')
  const [filter, setFilter] = useState('Tutti')
  const [orders, setOrders] = useState([])
  const [deleted, setDeleted] = useState(null)

  useEffect(() => {
    var myHeaders = new Headers()
    myHeaders.append('Content-Type', 'application/json')
    myHeaders.append('x-auth-token', localStorage.getItem('cut-catering-token'))

    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow',
    }

    fetch('https://app.cutcatering.it/api/users/', requestOptions)
      .then(response => response.json())
      .then(result => {
        console.log(result)
        setOrders(result)
      })
      .catch(error => console.log('error', error))
  }, [deleted])

  const filterOptions = ['Tutti', 'Producers', 'Singoli']

  let getOrders = function () {
    return orders
      .filter(e => {
        if (!search || e.customer.toLowerCase().includes(search.toLowerCase()))
          return true

        if (!search || e.name.toLowerCase().includes(search.toLowerCase()))
          return true
        return false
      })
      .filter(e => {
        if (
          filter === 'Tutti' ||
          (filter === 'Singoli' && e.type === 'single') ||
          (filter === 'Producers' && e.type === 'producer')
        )
          return true
        return false
      })
  }

  let resetPassword = id => {
    var myHeaders = new Headers()
    myHeaders.append('Content-Type', 'application/json')
    myHeaders.append('x-auth-token', localStorage.getItem('cut-catering-token'))

    fetch(
      'https://app.cutcatering.it/api/users/reset-password/' + encodeURI(id),
      {
        method: 'POST',
        headers: myHeaders,
        redirect: 'follow',
      },
    )
      .then(response => response.text())
      .then(result => {
        console.log(result)
      }) //()
      .catch(error => console.log('error', error))
  }

  return (
    <div className="dashboard-container">
      <div className="column">
        <TitleCard title="Utenti" subtitle={`Tutti gli utenti registrati!`} />
      </div>
      <div className="column">
        <FiltersAddBar
          search={search}
          setSearch={setSearch}
          filter={filter}
          setFilter={setFilter}
          newPath="/users/new"
          filterOptions={filterOptions}
        />
      </div>
      <div className="column-wrap">
        <div className="column-100 column-child-lg-50">
          {getOrders().map((el, key) => (
            <div key={key} className="col-element">
              <UserListItem
                user={el}
                setDeleted={setDeleted}
                resetPassword={() => resetPassword(el.email)}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default Users

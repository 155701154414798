import React, { useEffect, useState } from 'react'
import TitleCard from '../components/TitleCard'
import CustomerOrderStatus from '../components/CustomerOrderStatus'
import FiltersAddBar from '../components/FiltersAddBar'

function Customers(props) {
  const [search, setSearch] = useState('')
  const [filter, setFilter] = useState('Tutti')
  const [customers, setCustomers] = useState([])
  const [change, setChange] = useState(null)
  const filterOptions = ['Tutti', 'Attivi', 'Chiusi']

  useEffect(() => {
    var myHeaders = new Headers()
    myHeaders.append('Content-Type', 'application/json')
    myHeaders.append('x-auth-token', localStorage.getItem('cut-catering-token'))

    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow',
    }

    fetch('https://app.cutcatering.it/api/customers/', requestOptions)
      .then(response => response.json())
      .then(result => {
        console.log(result)
        setCustomers(result)
      })
      .catch(error => console.log('error', error))
  }, [change])

  let getCustomers = function () {
    return customers
      .filter(e => {
        if (!search || e.name.toLowerCase().includes(search.toLowerCase()))
          return true
        return false
      })
      .filter(e => {
        if (
          filter === 'Tutti' ||
          (filter === 'Attivi' && e.status === true) ||
          (filter === 'Chiusi' && e.status === false)
        )
          return true
        return false
      })
  }
  return (
    <div className="dashboard-container">
      <div className="column">
        <TitleCard title="Clienti" subtitle={`Dividi ogni commessa!`} />
      </div>
      <div className="column">
        <FiltersAddBar
          search={search}
          setSearch={setSearch}
          filter={filter}
          setFilter={setFilter}
          newPath="/customers/new"
          filterOptions={filterOptions}
        />
      </div>
      <div className="column-wrap">
        <div className="column-100 column-child-lg-50">
          {getCustomers().map((el, key) => (
            <div key={key} className="col-element">
              <CustomerOrderStatus customer={el} setChange={setChange} />
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default Customers

import React, { useEffect, useState } from 'react'
import TitleCard from '../components/TitleCard'
import MenuOptionStatus from '../components/MenuOptionStatus'
import FiltersAddBar from '../components/FiltersAddBar'

function Menu(props) {
  const [search, setSearch] = useState('')
  const [filter, setFilter] = useState('Attivi')
  const [menu, setMenu] = useState([])
  const [reload, triggerReload] = useState(0)
  const filterOptions = ['Tutti', 'Attivi', 'Nascosti']

  useEffect(() => {
    var myHeaders = new Headers()
    myHeaders.append('Content-Type', 'application/json')
    myHeaders.append('x-auth-token', localStorage.getItem('cut-catering-token'))

    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow',
    }

    fetch('https://app.cutcatering.it/api/menus/', requestOptions)
      .then(response => response.json())
      .then(result => {
        console.log(result)
        setMenu(result)
      })
      .catch(error => console.log('error', error))
  }, [reload])

  let getCustomers = function () {
    return menu
      .filter(e => {
        if (!search || e.name.toLowerCase().includes(search.toLowerCase()))
          return true
        if (
          !search ||
          e.description.toLowerCase().includes(search.toLowerCase())
        )
          return true
        return false
      })
      .filter(e => {
        if (
          filter === 'Tutti' ||
          (filter === 'Attivi' && e.hidden !== true) ||
          (filter === 'Nascosti' && e.hidden === true)
        )
          return true
        return false
      })
  }

  let toggleHidden = menu => {
    var myHeaders = new Headers()
    myHeaders.append('Content-Type', 'application/json')
    myHeaders.append('x-auth-token', localStorage.getItem('cut-catering-token'))

    fetch('https://app.cutcatering.it/api/menus/' + menu._id, {
      method: 'POST',
      headers: myHeaders,
      body: JSON.stringify({
        hidden: !(menu.hidden || false),
      }),
      redirect: 'follow',
    })
      .then(response => response.text())
      .then(result => {
        triggerReload(reload + 1)
      }) //()
      .catch(error => console.log('error', error))
  }

  return (
    <div className="dashboard-container">
      <div className="column">
        <TitleCard title="Menu" subtitle={`Cosa si mangia oggi?`} />
      </div>
      <div className="column">
        <FiltersAddBar
          search={search}
          setSearch={setSearch}
          filter={filter}
          setFilter={setFilter}
          newPath="/menu/new"
          filterOptions={filterOptions}
        />
      </div>
      <div className="column-wrap">
        <div className="column-100 column-child-lg-50">
          {getCustomers().map((el, key) => (
            <div key={key} className="col-element">
              <MenuOptionStatus option={el} toggleHidden={toggleHidden} />
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default Menu

import React from 'react'
import { Link } from 'react-router-dom'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'

function FiltersAddBar(props) {
  return (
    <div className="filters-add-row">
      <div className="filters-add-col">
        <div className="filter-box">
          <input
            type="text"
            placeholder="Cerca..."
            value={props.search}
            onChange={e => props.setSearch(e.target.value)}
          />
        </div>
        <div className="filter-box">
          <label htmlFor="stato">Stato: </label>

          <select
            name="stato"
            id="stato"
            value={props.filter}
            onChange={e => props.setFilter(e.target.value)}
          >
            {props.filterOptions.map((el, key) => (
              <option key={key} value={el}>
                {el}
              </option>
            ))}
          </select>
        </div>
      </div>
      <div className="filters-add-col">
        <Link to={props.newPath}>
          <div className="card-button card-button-primary">
            <FontAwesomeIcon icon={faPlus} /> Nuovo
          </div>
        </Link>
      </div>
    </div>
  )
}

export default FiltersAddBar

import React from 'react'

function NumberCard(props) {
  return (
    <div className="card number-card">
      <p className="number-card-num">{props.number || 0}</p>
      <p className="number-card-name">{props.name || ''}</p>
    </div>
  )
}

export default NumberCard
